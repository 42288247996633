.routine-filter-button__input {
	@apply absolute opacity-0 hidden;
}

.routine-filter-button__label {
	@apply inline-flex items-center justify-center font-bold border border-green-500 bg-green-100 text-green-500 shadow-md px-2.5 py-2 rounded-md cursor-pointer;
}

.routine-filter-button__input:checked + .routine-filter-button__label {
	@apply bg-green-500 text-white;
}
