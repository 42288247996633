.flyout-trigger {
	@apply relative cursor-pointer;
}
.flyout-trigger.active,
.flyout-trigger:hover {
	@apply text-black;
	transition: all 0.3s ease-out;
}
.flyout {
	top: 100%;
	@apply absolute right-0 z-30 p-4 ;
	transition: all 0.3s ease-out;
}
