/* Flickity */
.flickity {
	.flickity-button{
		color:white;
		background:none;
	}
	.flickity-prev-next-button.previous{
		@screen md{
			left:-76px
		}
	}
	.flickity-prev-next-button.next{
		@screen md{
			right:-76px
		}
	}
	.flickity-button:hover{
		background-color:transparent;
	}

	.flickity-page-dots .dot{
		@apply bg-green-300 opacity-100;
	}
	.flickity-page-dots .dot.is-selected{
		@apply bg-green-500;
	}

	.flickity-slider .beer-slider{
		display:block;
		overflow: visible;
		position: static;
	}

	.flickity-page-dots{
		bottom:-35px;
	}
}
