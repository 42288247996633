.sp-t {
	@apply mt-4;
	@screen md{
		@apply mt-8;
	}
}

.sp-t--md {
	@apply mt-4;
	@screen md{
		@apply mt-16;
	}
}

.sp-t--lg {
    @apply mt-8;
    @screen md{
        @apply mt-24;
    }
}

