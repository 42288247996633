.bg-header-color {
    background: var(--background-color, theme(colors.green.200));
}
.bg-header-svg-color {
    color: var(--background-color, theme(colors.green.200));
}

.contact__bg {
    overflow: hidden;
    &:after {
        content: "";
        width: 30%;
        height: 200%;
        z-index: 1;
        transform: rotate(-23deg);
        transform-origin: 0% 0%;
        @apply absolute right-0 top-0 bg-green-500;
    }
}

.mobile-menu__bg {
    overflow: hidden;
    &:after {
        content: "";
        width: 65%;
        height: 1000px;
        z-index: -1;
        transform: rotate(-23deg);
        transform-origin: 0% 0%;
        @apply absolute right-0 top-0;
        @apply bg-green-300;
        //background-image: linear-gradient(to right, #d9ebed 0%, #e2f2f6 100%);
    }
}

#modal-language-switcher {
    .modal__container {
        @apply p-0;
        max-height: 89vh;
    }

    .modal__content {
        @apply mt-0;
    }
}

.hero-home__bg{
    height:800px;
    @screen sm {
        height:500px;
    }
    overflow: hidden;
    &:after{
        content:'';
        width:49%;
        height:200%;
        z-index: 1;
        transform: rotate(-23deg);
        transform-origin: 0% 0%;
        background-image: linear-gradient(270deg, #28a3a7 0%, #009196 100%);
        @apply absolute right-0 bg-green-dark;
    }
}
