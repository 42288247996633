/* Before / After slider */
.icv__theme-wrapper {
	width: 40px;
	@screen md {
		width: 70px;
	}
}
.icv__arrow-wrapper {
	filter: brightness(0) invert(1);
	width: 16px;
	height: 16px;

	svg {
		width: 16px !important;
		height: 16px !important;
	}

	@screen md {
		width: 25px;
		height: 25px;

		svg {
			width: 25px !important;
			height: 25px !important;
		}
	}
}
.icv__circle {
	@apply bg-green-500;
	width: 32px;
	height: 32px;
	@screen md {
		width: 82px;
		height: 82px;
	}
}

/* Buy CTA */
[data-buy-cta-slider] .flickity-page-dot {
	border: 1px solid #ffffff;
	background: none;
	&.is-selected {
		@apply bg-white;
	}
}

/* Advice */
.advice-dropdown {
	@apply inline-flex items-center justify-between font-bold bg-white shadow-md px-2.5 py-3 appearance-none rounded-md pr-7 text-xs md:text-base;
	min-height: 39px;
	background-size: 13.6px 8.2px;
    background-position: calc(100% - .75rem) 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.646 8.282'%3E%3Cpath data-name='Path 1130' d='M6.293 8.062a.75.75 0 0 0 1.061 0l6.069-6.073a.75.75 0 0 0 0-1.061l-.7-.708a.75.75 0 0 0-1.059 0L6.823 5.032 1.987.219a.75.75 0 0 0-1.059 0L.223.928a.75.75 0 0 0 0 1.061Z' fill='%23303535'/%3E%3C/svg%3E");
}

/* Sticky box */
.sticky-box {
    @apply flex opacity-0 items-center justify-center p-6;
    box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.02);
    pointer-events: none;
    will-change: opacity;
    transition: opacity 350ms ease;
}

.show-stickies .sticky-box {
    opacity: 1;
    pointer-events: auto;
}

/* faq arrow */
.faq-arrow {
    transition: 0.3s ease-in-out;
    min-width: 11px;
}
.toggle svg {
	transform: rotate(-90deg);
	@screen md {
		transform: none;
	}
}
.toggle .active svg {
	transform: none;
	@screen md {
		transform: rotate(180deg);
	}
}

/* Routines */
[data-routine-slider-nav] .flickity-button {
	@apply text-white;
	background: none;
}
