.usp {
	li {
		@apply pl-6 relative;
		@screen md {
			@apply pl-10
		}
	}

	li::before {
		content:'';
		@apply absolute top-0.5 left-0 bg-no-repeat;
		width: 17px;
		height: 13px;
		background-size: 17px 13px;
		@screen md {
			width: 24px;
			height: 18px;
			background-size: 24px 18px;
		}
		background-position: 50% 50%;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.918' height='22.485' viewBox='0 0 29.918 22.485'%3E%3Cpath id='Union_1' data-name='Union 1' d='M-54.554-521.378a.692.692,0,0,1-.68-.693V-525.4H-58.56a.686.686,0,0,1-.693-.693v-1.47a.683.683,0,0,1,.693-.679h3.326v-3.326a.684.684,0,0,1,.68-.693h1.469a.687.687,0,0,1,.693.693v3.326h3.326a.692.692,0,0,1,.693.679v1.47a.7.7,0,0,1-.693.693h-3.326v3.326a.7.7,0,0,1-.693.693Zm-14.906-3.478-8.45-8.451a1.3,1.3,0,0,1,0-1.839l1.839-1.838a1.3,1.3,0,0,1,1.838,0l5.693,5.693,12.193-12.191a1.3,1.3,0,0,1,1.838,0l1.838,1.838a1.3,1.3,0,0,1,0,1.839l-14.95,14.95a1.3,1.3,0,0,1-.919.38A1.3,1.3,0,0,1-69.46-524.856Z' transform='translate(78.291 543.863)' fill='%23139a9f'/%3E%3C/svg%3E%0A");
	}
}
.usp.usp--small {
	li {
		@apply pl-6;
	}
	li::before {
		width: 17px;
		height: 13px;
		margin-top: 2px;
	}
	li.usp-stock-status::before {
		background-size: 14px 14px;
		height: 14px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'%3E%3Cg id='noun-check-circle-2349309' transform='translate(-14.5 -14.5)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M27.5,14.5a13,13,0,1,0,13,13A13,13,0,0,0,27.5,14.5Zm0,23.8A10.8,10.8,0,1,1,38.3,27.5,10.8,10.8,0,0,1,27.5,38.3Z' fill='%23139a9f'/%3E%3Cpath id='Path_2' data-name='Path 2' d='M43.315,37.33,36.5,44.141,33.868,41.5a1.088,1.088,0,0,0-1.538,1.538l3.406,3.406a1.062,1.062,0,0,0,1.538,0l7.58-7.58a1.088,1.088,0,0,0-1.538-1.538Z' transform='translate(-11.092 -14.261)' fill='%23139a9f'/%3E%3C/g%3E%3C/svg%3E%0A");
	}
    li.usp-stock-status.out-of-stock::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.469 16.469'%3E%3Cpath fill='%23139a9f' d='M16.468 8.234A8.236 8.236 0 0 0 8.234 0 8.236 8.236 0 0 0 0 8.234a8.234 8.234 0 0 0 8.234 8.234 8.234 8.234 0 0 0 8.234-8.234Zm-8.234 1.66a1.527 1.527 0 0 1 1.527 1.527 1.527 1.527 0 0 1-1.527 1.528 1.527 1.527 0 0 1-1.527-1.527 1.527 1.527 0 0 1 1.527-1.528Zm-1.45-5.485a.4.4 0 0 1 .4-.42h2.1a.4.4 0 0 1 .4.42l-.25 4.511a.4.4 0 0 1-.4.377H7.428a.4.4 0 0 1-.4-.377Z' data-name='Path 1282'/%3E%3C/svg%3E");
		background-size: 14px 14px;
		height: 14px;
	}
	li.usp-free-shipping::before {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='24.2' viewBox='0 0 319.358 220.522'%3E%3Cg id='noun-shipping-2409353' transform='translate(-84 -96.322)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M475.534,137.669,387.458,97.33a13.114,13.114,0,0,0-10.086,0L289.3,137.669a12.2,12.2,0,0,0-7.061,11.093V264.4A12.194,12.194,0,0,0,289.3,275.5l88.075,40.339a13.108,13.108,0,0,0,10.083,0L475.531,275.5a12.2,12.2,0,0,0,7.061-11.093l0-115.641a12.194,12.194,0,0,0-7.061-11.093Zm-93.118-15.462L440.909,149.1l-58.493,26.894L323.923,149.1Zm-75.637,45.718,63.535,28.91v88.748l-63.535-28.91Zm87.739,117.658V196.835l63.535-28.91v88.748Z' transform='translate(-79.236)' fill='%23139a9f'/%3E%3Cpath id='Path_2' data-name='Path 2' d='M196.47,180.32H163.862a12.1,12.1,0,1,0,0,24.2H196.47a12.052,12.052,0,0,0,12.1-12.1c.338-6.388-5.377-12.1-12.1-12.1Z' transform='translate(-27.084 -33.574)' fill='%23139a9f'/%3E%3Cpath id='Path_3' data-name='Path 3' d='M183.041,259.84H130.264a12.1,12.1,0,1,0,0,24.2h53.114a12.052,12.052,0,0,0,12.1-12.1,12.342,12.342,0,0,0-12.44-12.1Z' transform='translate(-13.655 -65.359)' fill='%23139a9f'/%3E%3Cpath id='Path_4' data-name='Path 4' d='M169.386,338.8H96.1a12.1,12.1,0,1,0,0,24.2h73.284a12.052,12.052,0,0,0,12.1-12.1C181.826,344.179,176.111,338.8,169.386,338.8Z' transform='translate(0 -96.92)' fill='%23139a9f'/%3E%3C/g%3E%3C/svg%3E%0A");
		background-size: 17px 13px;
	}
	li.usp-price-per-application::before {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'%3E%3Cpath id='noun-number-3844654' d='M159.5,70h-13a6.5,6.5,0,0,0-6.5,6.5v13a6.5,6.5,0,0,0,6.5,6.5h13a6.5,6.5,0,0,0,6.5-6.5v-13a6.5,6.5,0,0,0-6.5-6.5Zm4.333,19.5a4.334,4.334,0,0,1-4.333,4.333h-13a4.334,4.334,0,0,1-4.333-4.333v-13a4.334,4.334,0,0,1,4.333-4.333h13a4.334,4.334,0,0,1,4.333,4.333Zm-4.041-10.725h-1.733l.357-1.755a1.1,1.1,0,0,0-2.167-.412l-.412,2.167h-4.062l.347-1.755h0a1.1,1.1,0,0,0-2.167-.412l-.412,2.167h-2.167a1.083,1.083,0,1,0,0,2.167h1.733l-.8,4.117h-2.167a1.083,1.083,0,1,0,0,2.167h1.733l-.293,1.755a1.083,1.083,0,0,0,.856,1.278h.228a1.083,1.083,0,0,0,1.083-.878l.412-2.167h4.084l-.347,1.755a1.084,1.084,0,0,0,.856,1.278h.206a1.083,1.083,0,0,0,1.083-.878l.412-2.167h2.167a1.083,1.083,0,1,0,0-2.167h-1.733l.8-4.117h2.167a1.083,1.083,0,1,0,0-2.167Zm-5.157,6.283h-4.073l.8-4.117h4.073Z' transform='translate(-140 -70)' fill='%23139a9f'/%3E%3C/svg%3E%0A");
		background-size: 13px 13px;
	}
	li.usp-payment-methods::before {
		background-size: 15px 11px;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='19.415' viewBox='0 0 26 19.415'%3E%3Cg id='noun-payment-4584154' transform='translate(-177.518 -151.199)'%3E%3Cpath id='Path_5' data-name='Path 5' d='M181.418,170.615h18.2a3.909,3.909,0,0,0,3.9-3.908V162.6a1.3,1.3,0,1,0-2.6,0v4.153a1.3,1.3,0,0,1-1.3,1.3h-18.2a1.3,1.3,0,0,1-1.3-1.3v-6.685h22.1a1.292,1.292,0,0,0,1.3-1.3v-3.655a3.909,3.909,0,0,0-3.9-3.908h-18.2a3.909,3.909,0,0,0-3.9,3.908v11.6a3.909,3.909,0,0,0,3.9,3.908Zm0-16.807h18.2a1.3,1.3,0,0,1,1.3,1.3v2.355h-20.8v-2.355a1.3,1.3,0,0,1,1.3-1.3Z' fill='%23139a9f'/%3E%3Cpath id='Path_6' data-name='Path 6' d='M242.211,305.54a1.3,1.3,0,1,0,0,2.6h5.234a1.3,1.3,0,1,0,0-2.6Z' transform='translate(-58.615 -142.708)' fill='%23139a9f'/%3E%3C/g%3E%3C/svg%3E%0A");
	}
}
