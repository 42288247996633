.form-field {
}

/**
 * Use flex to remove spacing from label. We could also put display block
 * on the label but that would make it clickable accross the whole width.
 */
.form-label-wrapper {
    @apply mb-2 flex flex-col items-start;
}

.form-label {
    @apply leading-tight;
}

.form-instructions {
    @apply text-sm;
}

/**
 * 1. Height copied from the inspector. This hacky height is necessary for
 * Chrome. The height on the date input field was always wrong.
 */
.form-control {
    @apply block w-full border-b border-green-600 leading-normal bg-white text-black text-xl;
    height: 46px;
}
.form-control::placeholder {
    @apply opacity-100 text-black;
}
.form-control--file {
    @apply h-auto p-1;
}
.form-control--file::file-selector-button {
    @apply rounded-md border-none uppercase text-sm border-2 inline-block bg-green-600 border-green-600 text-white shadow-md;
    font-weight: 500;
    padding: 0.865rem 1.2rem 0.75rem;
    &:hover {
        @apply bg-green-500 border-green-500 text-white;
    }
}

.form-control:focus {
    @apply outline-none border-black;
}

.form-control--textarea {
    height: auto;
}

/**
 * 1. Update color of arrow in the fill attribute. %23 is an encoded hashtag.
 */
.form-control--select {
    @apply appearance-none pr-8;
    background-size: 13.6px 8.2px;
    background-position: calc(100% - .75rem) 50%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.646 8.282'%3E%3Cpath data-name='Path 1130' d='M6.293 8.062a.75.75 0 0 0 1.061 0l6.069-6.073a.75.75 0 0 0 0-1.061l-.7-.708a.75.75 0 0 0-1.059 0L6.823 5.032 1.987.219a.75.75 0 0 0-1.059 0L.223.928a.75.75 0 0 0 0 1.061Z' fill='%23303535'/%3E%3C/svg%3E");
}

.form-checkbox,
.form-radio {
    @apply inline-flex items-center;
}

.form-checkbox__input,
.form-radio__input {
    @apply absolute opacity-0;
}

.form-checkbox__replacement,
.form-radio__replacement {
    @apply block relative mr-2 flex-shrink-0;
    height: 18px;
    width: 18px;
    background: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.625 0H1.875A1.875 1.875 0 0 0 0 1.875v13.75A1.875 1.875 0 0 0 1.875 17.5h13.75a1.875 1.875 0 0 0 1.875-1.875V1.875A1.875 1.875 0 0 0 15.625 0Zm-.234 15.625H2.109a.235.235 0 0 1-.234-.234V2.109a.235.235 0 0 1 .234-.234h13.282a.235.235 0 0 1 .234.234v13.282a.235.235 0 0 1-.234.234Z' fill='%235E6973'/%3E%3C/svg%3E")  no-repeat center center;
}

.form-radio__replacement {
    @apply rounded-full;
}

.form-checkbox__input:focus + .form-checkbox__replacement,
.form-radio__input:focus + .form-radio__replacement {
    @apply border-black;
}

.form-checkbox__input:checked + .form-checkbox__replacement::before {
    @apply absolute inset-0;
    content: '';
    background: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.625 17.5a1.875 1.875 0 0 0 1.875-1.875V1.875A1.875 1.875 0 0 0 15.625 0H1.875A1.875 1.875 0 0 0 0 1.875v13.75A1.875 1.875 0 0 0 1.875 17.5h13.75Zm-8-3.83a.624.624 0 0 1-.884 0L2.683 9.607a.625.625 0 0 1 0-.884l.884-.884a.625.625 0 0 1 .884 0l2.737 2.737 5.862-5.862a.624.624 0 0 1 .884 0l.884.884a.624.624 0 0 1 0 .884L7.625 13.67Z' fill='%235E6973'/%3E%3C/svg%3E") no-repeat center center;
}

.form-radio__input:checked + .form-radio__replacement::before {
    @apply absolute inset-0 bg-green-300;

    content: '';
    margin: 2px;
}

.form-radio__input:checked + .form-radio__replacement::before {
    @apply rounded-full;
}

.form-checkbox__label,
.form-radio__label {
    @apply pr-2 text-gray-500 text-base leading-8;
}

.hf-warning {
    @apply text-xs leading-tight mt-1;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    @apply hidden;
}
