/* Gallery */
[data-product-gallery-main] {
    .flickity-prev-next-button.previous {
        @apply left-0;
    }
    .flickity-prev-next-button.next {
        @apply right-0;
    }
    .flickity-button {
        @apply text-green-600;
        background: none;
    }
}

.product_usp-row {
    top: 40px;
}

.product_usp-row--icon {
    &::before {
        @apply absolute bg-green-300;
        content: " ";
        width: 80%;
        left: 10%;
        z-index: 3;
        height: 6px;
        top: 15px;
    }

    svg {
        @apply relative bg-white;
        z-index: 4;
    }
}
