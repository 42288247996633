.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.visuallyhidden {
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.component-container {
    overflow: hidden;
}

.shopify-buy__type--center {
    text-align: center;
}

.shopify-buy--visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}
/*
.shopify-buy__btn {
    color: #fff;
    font-size: 15px;
    background-color: #78b657;
    padding: 12px 40px;
    letter-spacing: .3px;
    display: block;
    border-radius: 3px;
    cursor: pointer;
    -webkit-transition: background 200ms ease;
    transition: background 200ms ease;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.2;
    border: 0;
    -moz-appearance: none;
    -webkit-appearance: none
}

.shopify-buy__btn:hover,
.shopify-buy__btn:focus {
    background-color: #5f9d3e;
}

.shopify-buy__btn--parent {
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer
} */

.shopify-buy__btn--parent:hover .product__variant-img, .shopify-buy__btn--parent:focus .product__variant-img {
    opacity: .7;
}

.shopify-buy__btn--cart-tab {
    padding: 5px 11px;
    border-radius: 3px 0 0 3px;
    position: fixed;
    right: 0;
    top: 50%;
    -webkit-transform: translate(100%, -50%);
    transform: translate(100%, -50%);
    opacity: 0;
    min-width: inherit;
    width: auto;
    height: auto;
    z-index: 2147483647
}

.shopify-buy__btn--cart-tab.is-active {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
}

.shopify-buy__btn__counter {
    display: block;
    margin: 0 auto 10px auto;
    font-size: 18px;
}

.shopify-buy__icon-cart--side {
    height: 20px;
    width: 20px;
}

.shopify-buy__btn[disabled] {
    background-color: #999;
    border: none;
    pointer-events: none;
}

.shopify-buy__btn--close {
    position: absolute;
    right: 9px;
    top: 8px;
    font-size: 35px;
    color: #767676;
    border: none;
    background-color: transparent;
    -webkit-transition: color 100ms ease, -webkit-transform 100ms ease;
    transition: color 100ms ease, -webkit-transform 100ms ease;
    transition: transform 100ms ease, color 100ms ease;
    transition: transform 100ms ease, color 100ms ease, -webkit-transform 100ms ease;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding-right: 9px
}

.shopify-buy__btn--close:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    color: hsl(0, 0%, 41.2745098039%);
}

.shopify-buy__quantity-decrement,
.shopify-buy__quantity-increment {
    color: #4c4c4c;
    display: block;
    height: 30px;
    float: left;
    line-height: 16px;
    font-family: monospace;
    width: 26px;
    padding: 0;
    border: none;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    border: 1px solid #767676;
    position: relative
}

.shopify-buy__quantity-decrement svg, .shopify-buy__quantity-increment svg {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6px;
    margin-left: -7px;
    fill: currentColor;
}

.shopify-buy__quantity-decrement {
    border-radius: 3px 0 0 3px;
}

.shopify-buy__quantity-increment {
    border-radius: 0 3px 3px 0;
}

.shopify-buy__quantity {
    color: black;
    width: 45px;
    height: 30px;
    font-size: 16px;
    border: none;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    display: inline-block;
    padding: 0;
    border-radius: 0;
    border-top: 1px solid #767676;
    border-bottom: 1px solid #767676;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.shopify-buy__quantity-container.shopify-buy__quantity-with-btns {
    overflow: hidden
}

.shopify-buy__quantity-container.shopify-buy__quantity-with-btns .shopify-buy__quantity {
    border-left: 0;
    border-right: 0;
    float: left;
}
/*
.shopify-buy__option-select-wrapper {
    border: 1px solid #d3dbe2;
    border-radius: 3px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    background: #fff;
    overflow: hidden;
    vertical-align: bottom;
}

.shopify-buy__select-icon {
    cursor: pointer;
    display: block;
    fill: #798c9c;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -6px;
    pointer-events: none;
    width: 12px;
    height: 12px;
    vertical-align: middle;
}

.shopify-buy__option-select+.shopify-buy__option-select {
    margin-top: 7.5px;
}

.shopify-buy__option-select__label {
    display: block;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
}

.shopify-buy__btn--parent .shopify-buy__option-select__label {
    cursor: pointer;
}

.shopify-buy__option-select__select {
    font-size: inherit;
    padding: 7px 10px;
    padding-right: 32px;
    border: 0;
    width: 100%;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none
}

.shopify-buy__option-select__select::-ms-expand {
    display: none;
}

.shopify-buy__btn--parent .shopify-buy__option-select__select {
    cursor: pointer;
}

.shopify-buy__product {
    overflow: hidden;
    width: 100%;
}

.shopify-buy__product__variant-img {
    margin: 0 auto 15px auto;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    opacity: 1
}

.shopify-buy__product__variant-img.is-transitioning {
    opacity: 0;
}

.shopify-buy__is-button {
    cursor: pointer;
}

.shopify-buy__no-image .shopify-buy__product__variant-img {
    display: none;
} */

/* .shopify-buy__product__title {
    font-size: 18px;
    line-height: 1.2;
    color: #4a4a4a;
    margin-bottom: 15px;
    font-weight: 700;
}

.shopify-buy__layout-horizontal .shopify-buy__product__title {
    margin-top: 10px;
}

.shopify-buy__product__variant-title {
    font-size: 18px;
    color: #666;
    font-weight: 400;
    text-align: center;
    margin-bottom: 15px;
}

.shopify-buy__product__price {
    margin-bottom: 15px;
}

.shopify-buy__product-description {
    margin-top: 30px;
    line-height: 1.65;
    color: #4a4a4a
}

.shopify-buy__product-description p,
.shopify-buy__product-description ul,
.shopify-buy__product-description ol,
.shopify-buy__product-description img {
    margin-bottom: 10px
}

.shopify-buy__product-description p:last-child, .shopify-buy__product-description ul:last-child, .shopify-buy__product-description ol:last-child, .shopify-buy__product-description img:last-child {
    margin-bottom: 0;
}

.shopify-buy__product-description a {
    color: inherit;
}

.shopify-buy__product-description img {
    max-width: 100%;
}

.shopify-buy__product-description h1 {
    font-size: 20px;
}

.shopify-buy__product-description h2 {
    font-size: 18px;
}

.shopify-buy__product-description h3 {
    font-size: 17px;
}

.shopify-buy__product-description ul,
.shopify-buy__product-description ol {
    margin-left: 2em;
}

.shopify-buy__product-description ul {
    list-style-type: disc;
}

.shopify-buy__layout-vertical {
    text-align: center;
}

.shopify-buy__product__actual-price,
.shopify-buy__product__compare-price {
    color: #4a4a4a;
    display: inline-block;
}

.shopify-buy__product__actual-price {
    font-size: 14px;
}

.shopify-buy__product__compare-price {
    font-size: 12px;
    text-decoration: line-through;
    padding-left: 5px;
    opacity: 0.65;
}

.shopify-buy__product__unit-price {
    color: #4a4a4a;
    padding-top: 5px;
    font-size: 12px;
    opacity: 0.8;
}

.shopify-buy__product__variant-selectors {
    text-align: left;
    font-size: 14px;
}

.shopify-buy__layout-vertical .shopify-buy__product__variant-selectors {
    width: 100%;
    max-width: 280px;
    display: inline-block;
}

.shopify-buy__quantity {
    border-left: 1px solid;
    border-right: 1px solid;
    border-radius: 3px;
} */

/* .shopify-buy__quantity,
.shopify-buy__quantity-increment,
.shopify-buy__quantity-decrement {
    border-color: #d3dbe2;
    line-height: 1.2;
    font-size: 15px;
    height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
}

.shopify-buy__btn {
    display: inline-block;
}

.shopify-buy__btn-wrapper {
    margin-top: 20px;
}

.shopify-buy__btn.shopify-buy__beside-quantity {
    display: inline-block;
    vertical-align: top;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid transparent;
}

.shopify-buy__btn-and-quantity .shopify-buy__quantity {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;
}

.shopify-buy__btn-and-quantity .shopify-buy__quantity-container {
    display: inline-block;
    vertical-align: top;
}

.shopify-buy__btn-and-quantity .shopify-buy__btn-wrapper {
    display: inline-block;
    vertical-align: top;
    margin: 0;
} */

.shopify-buy__cart-item__quantity-container {
    margin-top: 20px;
    display: inline-block;
}
/*
.shopify-buy__layout-vertical .shopify-buy__btn,
.shopify-buy__layout-vertical .shopify-buy__quantity-container,
.shopify-buy__layout-horizontal .shopify-buy__btn,
.shopify-buy__layout-horizontal .shopify-buy__quantity-container {
    margin: 20px auto 0;
}

.shopify-buy__layout-vertical .shopify-buy__btn:first-child, .shopify-buy__layout-horizontal .shopify-buy__btn:first-child {
    margin-top: 0;
}

.shopify-buy__layout-vertical .shopify-buy__btn-and-quantity, .shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity {
    margin: 20px auto 0
}

.shopify-buy__layout-vertical .shopify-buy__btn-and-quantity .shopify-buy__btn,
.shopify-buy__layout-vertical .shopify-buy__btn-and-quantity .shopify-buy__quantity-container,
.shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity .shopify-buy__btn,
.shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity .shopify-buy__quantity-container {
    margin: 0 auto;
}

.shopify-buy__layout-vertical .shopify-buy__btn-and-quantity:first-child, .shopify-buy__layout-horizontal .shopify-buy__btn-and-quantity:first-child {
    margin: 0 auto;
}

.shopify-buy__layout-vertical .shopify-buy__product__variant-img, .shopify-buy__layout-horizontal .shopify-buy__product__variant-img {
    max-width: 100%;
}

@media (min-width: 500px) {
    .shopify-buy__layout-horizontal:not(.no-image) {
        text-align: left;
        margin-bottom: 0;
        margin-left: 0
    }

    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-img-wrapper {
        float: left;
        width: 40%;
    }

    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title {
        text-align: left;
    }

    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__title,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__price,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-description,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__btn-and-quantity,
    .shopify-buy__layout-horizontal:not(.no-image)>.shopify-buy__btn-wrapper,
    .shopify-buy__layout-horizontal:not(.no-image)>.shopify-buy__quantity-container,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-selectors {
        margin-left: calc(40% + 25px);
    }
}

@media (min-width: 680px) {
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-img-wrapper {
        float: left;
        width: 60%;
    }

    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__title,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-title,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__price,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product-description,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__btn-and-quantity,
    .shopify-buy__layout-horizontal:not(.no-image)>.shopify-buy__btn-wrapper,
    .shopify-buy__layout-horizontal:not(.no-image)>.shopify-buy__quantity-container,
    .shopify-buy__layout-horizontal:not(.no-image) .shopify-buy__product__variant-selectors {
        margin-left: calc(60% + 25px);
    }
}

.no-image .shopify-buy__product-img-wrapper {
    display: none;
} */

@-webkit-keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

/* .shopify-buy__carousel {
    font-size: 0;
    text-align: center;
    min-height: 90px;
    margin-left: -15px;
    margin-top: 15px;
} */

/* .shopify-buy__carousel-item {
    width: calc(16.666% - 15px);
    margin-left: 15px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 0;
    border: none
}

.shopify-buy__carousel-item:nth-child(n+7) {
    margin-top: 15px;
}

.shopify-buy__carousel-item:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.main-image-wrapper {
    position: relative;
} */

/* .carousel-button {
    position: absolute;
    width: 75px;
    top: 0;
    height: 100%;
    border: none;
    font-size: 0;
    background-color: transparent;
    opacity: 0.4;
    cursor: pointer
}

.carousel-button:hover,
.carousel-button:focus {
    opacity: 0.9;
    outline: none;
}

.carousel-button-arrow {
    width: 20px;
    display: inline-block;
    margin-left: 25px;
}

.carousel-button--previous {
    left: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.carousel-button--next {
    right: 0;
}

.shopify-buy__carousel-item--selected {
    opacity: 0.4;
} */

.shopify-buy__btn--close {
    right: 0px;
    font-size: 45px;
    font-weight: 100;
    z-index: 2147483647;
    padding: 0 10px;
}

/* .shopify-buy__modal {
    background: #fff;
    width: calc(100% - 20px);
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2147483646;
}

.shopify-buy__product {
    text-align: left;
}

.shopify-buy__product__title,
.shopify-buy__product__price,
.shopify-buy__product__variant-title {
    text-align: left;
}

.shopify-buy__product__title {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.4;
}

.shopify-buy__product__compare-price {
    display: inline-block;
    margin-right: 5px;
}

.shopify-buy__product__actual-price {
    display: inline-block;
}

.shopify-buy__modal .shopify-buy__modal-product-wrapper {
    width: 100%;
}

.shopify-buy__product__variant-image {
    margin: 0;
} */

/* @media (max-width: 499px) {
    body.is-active {
        overflow: hidden;
        position: fixed;
        height: 100vh;
        -webkit-transition: all 0s;
        transition: all 0s;
    }

    .shopify-buy__modal {
        width: 100%;
        min-height: 100vh;
        position: fixed;
        overflow-y: auto;
    }

    .shopify-buy__product {
        padding: 15px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .shopify-buy__product__variant-img {
        max-height: 60vh;
        margin: 0 auto;
        width: auto;
        max-width: 100%;
    }

    .shopify-buy__btn--close {
        position: fixed;
        top: 0;
        right: 0;
    }
} */

@-webkit-keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-200px);
        -webkit-transform: translateY(-200px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(-200px);
        -webkit-transform: translateY(-200px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
}

@-webkit-keyframes slideOut {
    from {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-200px);
        -webkit-transform: translateY(-200px);
    }
}

@keyframes slideOut {
    from {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-200px);
        -webkit-transform: translateY(-200px);
    }
}

/* @media (min-width: 500px) {

    html,
    body.is-active {
        height: 100%;
    }

    .shopify-buy__modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        overflow-y: scroll;
    }

    .shopify-buy__modal {
        margin: 100px auto 40px auto;
        opacity: 0;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.72);
        -webkit-transform: translateY(-200px);
        transform: translateY(-200px);
        max-width: 1000px;
        -webkit-animation: 200ms slideOut forwards;
        animation: 200ms slideOut forwards;
    }

    .is-active .shopify-buy__modal {
        -webkit-animation-name: slideIn;
        animation-name: slideIn;
    }

    .shopify-buy__product {
        padding: 30px;
    }

    .shopify-buy__product-img-wrapper {
        height: 100%;
        padding-right: 30px;
    }

    .shopify-buy__product__variant-img {
        margin: 0 auto;
    }

    .shopify-buy__btn--close {
        top: -60px;
        color: hsl(0, 0%, 100%)
    }

    .shopify-buy__btn--close:hover {
        color: #fff;
    }
}

@media (min-width: 680px) {
    .shopify-buy__product {
        padding: 45px;
    }
} */

@-webkit-keyframes flipIn {
    from {
        max-height: 0;
        -webkit-transform: rotatex(90deg) translatey(-50%);
        transform: rotatex(90deg) translatey(-50%);
        margin-bottom: -65px;
        opacity: 0;
    }

    to {
        max-height: none;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 20px;
        opacity: 1;
    }
}

@keyframes flipIn {
    from {
        max-height: 0;
        -webkit-transform: rotatex(90deg) translatey(-50%);
        transform: rotatex(90deg) translatey(-50%);
        margin-bottom: -65px;
        opacity: 0;
    }

    to {
        max-height: none;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 20px;
        opacity: 1;
    }
}

@-webkit-keyframes flipOut {
    from {
        max-height: none;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 20px;
        opacity: 1;
    }

    to {
        max-height: 0;
        -webkit-transform: rotatex(90deg) translatey(-50%);
        transform: rotatex(90deg) translatey(-50%);
        margin-bottom: -65px;
        opacity: 0;
    }
}

@keyframes flipOut {
    from {
        max-height: none;
        -webkit-transform: none;
        transform: none;
        margin-bottom: 20px;
        opacity: 1;
    }

    to {
        max-height: 0;
        -webkit-transform: rotatex(90deg) translatey(-50%);
        transform: rotatex(90deg) translatey(-50%);
        margin-bottom: -65px;
        opacity: 0;
    }
}

.shopify-buy__cart-wrapper {
    height: 100%;
    padding-left: 10px;
}

.shopify-buy__cart {
    height: 100%;
    background-color: #fff;
    width: calc(100% - 10px);
    position: absolute;
    right: 0;
    -webkit-box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
}

.shopify-buy__cart__header {
    padding: 20px;
    padding-right: 40px;
    position: relative;
    z-index: 2147483647;
    background-color: inherit;
}

.shopify-buy__cart__title {
    font-size: 18px;
    color: #767676;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shopify-buy__cart-scroll {
    padding: 70px 0 135px 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
}

.shopify-buy__cart-scroll--discounts {
    padding-bottom: 170px;
}

.shopify-buy__cart-scroll--cart-note {
    padding-bottom: 200px;
}

.shopify-buy__cart-scroll--cart-note.shopify-buy__cart-scroll--discounts {
    padding-bottom: 230px;
}

.shopify-buy__cart-items {
    /* display: none; */
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    padding: 0 20px 20px;
    -webkit-overflow-scrolling: touch;
    -webkit-perspective: 400px;
    perspective: 400px;
    -webkit-perspective-origin: 50% 0px;
    perspective-origin: 50% 0px;
    @screen md{
        height: 100%;
    }
}

.shopify-buy__cart-item {
    /* display: none !important; */
    min-height: 65px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;
    -webkit-backface-visibility: visible;
    backface-visibility: visible;
    -webkit-animation: 200ms flipIn forwards;
    animation: 200ms flipIn forwards;
}

.shopify-buy__cart-productMessage {
    padding: 40px 20px 0;

    img {
        width: 65px;
        display: block;
        float: left;
    }
    div {
        min-height: 65px;
        margin-left: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    span, strong {
        font-size: .9rem;
        line-height: 1.1em;
        display: block;
        color: #303535;
        text-align: right;
    }
    strong {
        font-weight: 800;
        margin-top: 5px;
    }
    &::after {
      content: "";
      clear: both;
      display: table;
    }
}

.shopify-buy__cart-item.is-hidden {
    -webkit-animation-name: flipOut;
    animation-name: flipOut;
}

.shopify-buy__cart-item__image {
    width: 65px;
    height: 65px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
}

.shopify-buy__cart-item__title {
    font-size: 14px;
    margin-left: 80px;
    margin-bottom: 3px;
    display: block;
}

.shopify-buy__cart-item__price {
    float: right;
    font-size: 14px;
    font-weight: bold;
    line-height: 26px;
    display: none;
}

.shopify-buy__cart-item__price-and-discounts {
    float: right;
    text-align: right;
    max-width: 100px
}

.shopify-buy__cart-item__price-and-discounts .shopify-buy__cart-item__price {
    float: none;
}

.shopify-buy__cart-item__full-price {
    font-size: 12px;
    line-height: 12px;
}

.shopify-buy__cart-item__discount {
    font-size: 12px;
    word-wrap: break-word;
    text-transform: uppercase
}

.shopify-buy__cart-item__discount+.shopify-buy__cart-item__discount {
    margin-top: 5px;
}

.shopify-buy__cart-item__discount__icon {
    width: 12px;
    height: 12px;
    vertical-align: top;
    fill: currentColor;
}

.shopify-buy__cart-item__variant-title {
    margin-left: 80px;
    margin-bottom: 10px;
    color: #4c4c4c;
    font-size: 12px;
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shopify-buy__cart-bottom {
    background-color: #fff;
    position: absolute;
    width: 100%;
    bottom: 0;
    left:0;
    padding: 15px 20px 20px 20px;
}

.shopify-buy__cart__subtotal__text {
    display: none !important;
    /* text-transform: uppercase;
    float: left;
    font-size: 11px;
    color: #4c4c4c; */
}

.shopify-buy__cart__subtotal__price {
    display: none !important;
    /* float: right; */
}

.shopify-buy__cart__discount {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
    color: #4c4c4c;
}

.shopify-buy__cart__discount__text {
    font-size: 11px;
    text-transform: uppercase;
    margin-right: 10px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
}

.shopify-buy__cart__discount__text__icon {
    width: 11px;
    height: 11px;
    vertical-align: top;
    fill: currentColor;
}

.shopify-buy__cart__discount__amount {
    font-size: 12px;
    line-height: 12px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.shopify-buy__cart__currency {
    font-size: 12px;
}

.shopify-buy__cart__notice {
    font-size: 11px;
    clear: both;
    padding-top: 10px;
    text-align: center;
    color: #4c4c4c;
}

.shopify-buy__cart__note {
    clear: both;
    padding-top: 10px;
}

.shopify-buy__cart__note__description {
    font-size: 11px;
    color: #4c4c4c;
}

.shopify-buy__cart__note__text-area {
    resize: none;
    font-size: 11px;
    width: 100%;
    color: #4c4c4c;
}

.shopify-buy__cart-empty-text {
    padding: 10px 15px;
    text-align: center;
}

.shopify-buy__btn--cart-checkout {
    clear: both;
    margin-top: 15px;
    width: 100%;
    padding: 10px 5px;
    font-size: 16px;
    background-color: #009196 !important;
    color: white !important;
}

.shopify-buy__cart-closeButton a,
.shopify-buy__cart-checkoutButton a {
    clear: both;
    width: 100%;
    padding: 10px 5px;
    font-size: 16px;
    font-weight: bold;
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    color: #007377;
    background-color: #def3f7;
    border-radius: 4px;
    width: 100%;
    display: block;
    text-align: center;
}

.shopify-buy__cart-checkoutButton a {
    background-color: #009196 !important;
    color: white !important;
    margin-top: 10px;
}

.shopify-buy__quantity-container {
    margin-left: 80px;
    margin-right: 100px;
    height: 26px;
    line-height: 26px;
}

.shopify-buy__quantity-container.is-loading {
    opacity: 0.65;
    pointer-events: none;
}

.shopify-buy__cart-item__quantity-input {
    float: left;
    background: transparent;
}

@media (max-width: 330px) {
    .shopify-buy__cart-item__price-and-discounts {
        max-width: 90px;
    }

    .shopify-buy__quantity-container {
        margin-right: 90px;
    }
}

.shopify-buy__cart-toggle-wrapper {
    display: inline-block;
}

.shopify-buy__cart-toggle {
    background-color: #78b657;
    color: #fff;
    border-radius: 3px 0 0 3px;
    ;
    padding: 8px 10px;
    text-align: center;
    display: inline-block;
    min-width: 46px;
    margin-right: 0;
    cursor: pointer;
    -webkit-transition: background 200ms ease;
    transition: background 200ms ease;

    /* pointer-events: none; */
    cursor: pointer;
}

.shopify-buy__cart-toggle:hover {
    background-color: #5f9d3e;
}

.shopify-buy__cart-toggle__count {
    font-size: 18px;
    margin-bottom: 10px;
}

.shopify-buy__icon-cart__group {
    fill: #fff;
}

.is-inline .shopify-buy__icon-cart,
.is-inline .shopify-buy__cart-toggle__title,
.is-inline .shopify-buy__cart-toggle__count {
    display: inline-block;
    vertical-align: middle;
}

.is-inline {
    .shopify-buy__icon-cart {
        margin-right: 5px;
    }

    .shopify-buy__cart-toggle__title {
        font-size: 16px;
        font-weight: normal;
    }

    .shopify-buy__cart-toggle__count {
        margin-left: 21px;
        margin-bottom: 0;
        position: relative
    }

    .shopify-buy__cart-toggle__count:before {
        content: "";
        display: block;
        position: absolute;
        left: -12px;
        height: 100%;
        width: 1px;
        background-color: #fff;
        opacity: 0.3;
    }
}

.is-inline.shopify-buy__cart-toggle {
    border-radius: 3px;
    padding: 5px 10px;
}

/* .shopify-buy__collection {
    overflow: hidden;
}

.shopify-buy__collection-products {
    margin-left: -15px;
    text-align: center
}

@media(min-width: 601px) {
    .shopify-buy__collection-products {
        margin-left: -20px
    }
}

.shopify-buy__product {
    min-width: 240px;
    width: auto;
    margin-left: 15px;
    display: inline-block;
    vertical-align: top
}

.shopify-buy__product+.shopify-buy__product {
    margin-top: 15px;
}

@media(min-width: 601px) {
    .shopify-buy__product {
        width: calc(25% - 20px);
        margin-left: 20px;
        margin-bottom: 50px
    }

    .shopify-buy__product+.shopify-buy__product {
        margin-top: 0;
    }
}

.shopify-buy__btn.shopify-buy__collection-pagination-button {
    display: none;
    margin: 15px auto
}

.shopify-buy__btn.shopify-buy__collection-pagination-button.is-active {
    display: block;
}

.shopify-buy-modal-is-active {
    height: 100%;
    overflow: auto;
} */

/* .shopify-buy-frame {
    display: inline-block
}

.shopify-buy-frame iframe {
    width: 100%;
    display: block;
    height: 0;
    overflow: hidden;
}

.shopify-buy-frame--cart {
    width: 100%;
    max-width: 350px;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 2147483647;
    transform: translateX(100%);
    -webkit-transform: translateX(100%)
}

.shopify-buy-frame--cart iframe {
    height: 100%;
}

.shopify-buy-frame--cart.is-initialized {
    -webkit-transition: all 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 250ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.shopify-buy-frame--cart.is-active {
    transform: translateX(0);
    -webkit-transform: translateX(0);
} */

/* .shopify-buy-frame--product {
    display: block
}

.shopify-buy-frame--product.shopify-buy__layout-horizontal {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.shopify-buy-frame--product.shopify-buy__layout-horizontal iframe {
    max-width: 100%
}

@media (min-width: 950px) {
    .shopify-buy-frame--product.shopify-buy__layout-horizontal iframe {
        max-width: 950px;
        margin-left: auto;
        margin-right: auto
    }
} */

.shopify-buy-frame--toggle {
    display: inline-block;
    cursor: pointer;
}

.shopify-buy-frame--toggle:not(.is-sticky) {
    overflow: hidden;
    padding: 5px;
}

.shopify-buy-frame--toggle.is-sticky {
    display: none;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 2147483645;
}

.shopify-buy-frame--toggle.is-active.is-sticky {
    display: block;
}

/* .is-active .shopify-buy-frame--toggle iframe {
    min-height: 67px;
}

.shopify-buy-frame--productSet {
    width: 100%;
}

.shopify-buy-frame--modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2147483646;
    display: none;
    -webkit-transition: background 300ms ease;
    transition: background 300ms ease
}

.shopify-buy-frame--modal iframe {
    height: 100%;
    width: 100%;
    max-width: none;
}

.shopify-buy-frame--modal.is-active {
    background: rgba(0, 0, 0, 0.6);
}

.shopify-buy-frame--modal.is-block {
    display: block;
}

.shopify-buy-frame--product .shopify-buy__product {
    padding: 0;
} */

.shopify-buy-frame--cart .shopify-buy__quantity {
    border-radius: 0;
    border-left: 0;
    border-left: 0;
    padding: 0;
    font-size: 16px;
}

.shopify-buy-frame--cart .shopify-buy__quantity,
.shopify-buy-frame--cart .shopify-buy__quantity-increment,
.shopify-buy-frame--cart .shopify-buy__quantity-decrement {
    height: 30px;
}

/* @media (max-width: 600px) {
    .shopify-buy-frame--modal .shopify-buy__product {
        width: 100%;
        padding: 30px;
        margin: 0;
    }
}

@media (min-width: 601px) {
    .shopify-buy-frame--modal .shopify-buy__product {
        width: 100%;
        padding: 45px;
        margin: 0;
    }
} */
