.t-alpha {
    @apply text-5xl md:text-9xl font-bold text-black;
    color: var(--title-color);
}
.t-beta {
    @apply text-lg md:text-6xl font-bold;
}

.t-gamma {
    @apply text-base md:text-5xl font-bold;
}

.t-delta {
    @apply text-base md:text-4xl font-bold;
}

.highlight {
    @apply px-1.25;
    box-decoration-break: clone;
    background: linear-gradient(180deg,hsla(0,0%,100%,0) 53%,var(--title-underline-color, #f4a49e) 0, var(--title-underline-color, #f4a49e) 85%,hsla(4,80%,79%,0) 0);
}
.highlight--green {
    @apply px-1.25;
    box-decoration-break: clone;
    background: linear-gradient(180deg,hsla(0,0%,100%,0) 53%,var(--title-underline-color, #48C6C4) 0, var(--title-underline-color, #48C6C4) 85%,hsla(4,80%,79%,0) 0);
}
.highlight--light-green {
    @apply px-1.25;
    box-decoration-break: clone;
    background: linear-gradient(180deg,hsla(0,0%,100%,0) 53%,var(--title-underline-color, #C2DFE3) 0, var(--title-underline-color, #C2DFE3) 85%,hsla(4,80%,79%,0) 0);
}


/* .t-alpha--bg-pink strong{
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 55%,#f7bbbb 56%,#f7bbbb 75%, rgba(0,0,0,0) 76%, rgba(0,0,0,0) 100%);
} */
.t-alpha--bg-green strong{
    background-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 55%,#64ccc9 56%,#64ccc9 75%, rgba(0,0,0,0) 76%, rgba(0,0,0,0) 100%);
}



