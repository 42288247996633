:root {
    --container-sm: 912px;
    --container-md: 1134px;
    --container-base: 1184px;
    --container-limit: 1800px;
}

.container {
    @apply mx-auto px-4;

    max-width: var(--container-base);

    @screen md {
        @apply px-8;
    }
}

.container-sm {
    @apply mx-auto px-4;

    max-width: var(--container-sm);

    @screen md {
        @apply px-8;
    }
}

.container-md {
    @apply mx-auto px-4;

    max-width: var(--container-md);

    @screen md {
        @apply px-8;
    }
}

.container-limit {
    @apply mx-auto;
    max-width: var(--container-limit);
}
