.shopify-mini-cart:not(:empty) {
    @apply h-[26px] min-w-[26px] inline-flex items-center justify-center text-green-500 border border-green-500 rounded-full bg-white text-base;
}

.wishlist-count:not(:empty) {
    @apply h-[26px] min-w-[26px] inline-flex items-center justify-center text-green-500 border border-green-500 rounded-full bg-white text-base;
}

.shopify-buy__cart-toggle {
    background-color: #27cfca;
    font-size: 13px;
    font-weight: 500;
    color: #007377;

    &:hover {
        background-color: #27cfca;
    }

    &__count {
        margin-bottom: 2px;
    }

    .shopify-buy__icon-cart__group {
        fill: #007377;
    }

    .shopify-buy__cart-toggle__count {
        display: none;
    }

    .shopify-buy__icon-cart {
        margin-top: 7px;
    }
}

.shopify-buy__cart {
    color: #007377;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: stretch;
    align-items: stretch;
    max-height: 100%;
    overflow: auto;

    &>div {
        position: static !important;
    }

    .shopify-buy__cart-scroll {
        flex: 1 1 auto;
        padding: 0;
    }
}

.shopify-buy__btn--close {
    top: 5px;
    right: 5px;

    span {
        color: #007377;
    }
}

.shopify-buy__cart__title {
    font-weight: 700;
    color: #007377;
}

.shopify-buy__quantity-decrement {}

.shopify-buy__cart-item__quantity-input {
    border-right: 0;
}

.shopify-buy__quantity-increment {}

.shopify-buy__cart__subtotal__text {
    font-weight: 500;
    display: inline-block;
    padding-top: 9px;
}

.shopify-buy__cart__notice {
    padding-top: 0;
    margin-bottom: 14px;
    white-space: pre-line;
    text-align: left;
}

.shopify-buy__btn--cart-checkout {
    font-family: 'Catamaran', sans-serif;
    font-weight: bold;
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #007377;
    background-color: #27cfca;
    border-radius: 4px;

    &:hover {
        color: #007377;
        background-color: #27cfca;
    }

    &:focus {
        background-color: #27cfca;
    }
}

.shopify-buy-frame--toggle {
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    border-right: 0;
    border-radius: 4px;
}

.shopify-buy-frame--product {
    display: inline !important;
}

.shopify-buy__cart-message {
    padding: 0 20px 210px;

    p {
        @apply my-4;

        a {
            text-decoration: underline;
        }
    }
}

div.shopify-buy__cart-bottom {
    position: fixed !important;
    bottom: 0;
    left: 10px;
    width: 340px;
    @apply shadow-lg;
}

.shopify-buy__cart-buttons {
    &>div {
        @apply mb-4;
        min-height: 46px;

        &>div {
            /* min-width: 70px; */
        }
    }

    .shopify-buy__btn {
        margin-top: 0 !important;
    }

    .shopify-buy__btn--price {
        display: inline !important;
    }
}

.product_label--sale {
    @apply hidden;
}

.discounted .product_label--sale {
    @apply inline-block;
}

.product_label--no-stock {
    @apply hidden;
}

.no-stock {
    .product_label--no-stock {
        @apply inline-block;
    }

    /* .product_label--sale {
        @apply hidden;
    } */
    /* .product_detail--shopify {
        .button {
            @apply pointer-events-none cursor-not-allowed bg-green-300 border-green-300 text-grey-lighter;
            box-shadow: none;
            cursor: not-allowed !important;

            svg path {
                fill: #9eb5c1;
            }
        }
    } */

    .product_element--shopify {
        display: none !important;
    }

    .price-holder {
        padding-bottom: 33px;
    }

    .shopify-buy__out-of-stock {
        display: block;
        margin-bottom: -31px;
        margin-top: 8px;
        opacity: .8;
    }

    &.pre-order {
        .shopify-buy__out-of-stock {
            display: none;
        }

        .shopify-buy__pre-order {
            display: block;
            margin-bottom: -31px;
            margin-top: 8px;
            opacity: .8;
        }
    }
}

.pre-order {
    .price-holder {
        padding-bottom: 33px;
    }

    .shopify-buy__pre-order {
        display: block;
        margin-bottom: -31px;
        margin-top: 8px;
        opacity: .8;
    }
}

.soon-available {
    /* .product_detail--shopify {
        .button {
            @apply pointer-events-none cursor-not-allowed bg-green-300 border-green-300 text-grey-lighter;
            box-shadow: none;
            cursor: not-allowed !important;

            svg path {
                fill: #9eb5c1;
            }
        }
    } */

    .product_element--shopify {
        display: none !important;
    }

    .price-holder {
        padding-bottom: 33px;
    }

    .shopify-buy__soon-available {
        display: block;
        margin-bottom: -31px;
        margin-top: 8px;
        opacity: .8;
    }
}

.product_detail--shopify {
    /* @apply inline; */

    .price {
        @apply block;
    }

    /* .button {
        @apply button--green cursor-pointer mr-4;

        svg {
            width: 15px;
            height: 15px;
        }
    } */

    .price_current {
        @apply inline-block text-8xl font-bold;
    }

    .price_current:not(.out-of-stock) {
        @apply text-red-500;
    }

    .price_current.out-of-stock {
        @apply text-gray-200;
    }

    .price_old {
        @apply inline-block text-7xl opacity-50;
    }
}
.product_detail--shopify.product_detail--shopify-white {
    .price_current,
    .price_old {
        @apply text-white;
    }
}
.product_detail--shopify.normal-price-styling {
    .price_current:not(.out-of-stock) {
        @apply text-black;
    }

    .price_current {
        @apply text-4xl;
    }

    .price_old {
        @apply text-black text-2xl;
    }
    &.red {
        .price_current,
        .price_old {
            @apply text-red-500;
        }
    }
}

/* .product_button--white {
    .button {
        @apply button--green w-full;

        svg path {
            fill: #ffffff;
        }

        &:hover {
            @apply button--green;

            svg path {
                fill: #ffffff;
            }
        }
    }

    @screen xs {
        .button {
            @apply w-auto;
        }
    }

    @screen md {
        .button {
            @apply button--white;

            svg path {
                fill: #009196;
            }

            &:hover {
                @apply button--white;

                svg path {
                    fill: #ed6a6d;
                }
            }
        }
    }
} */

.product_element--shopify {
    @apply inline;

    .shopify-buy__product {
        display: inline !important;
    }

    .price {
        @apply hidden;
    }

    .button {
        @apply button--green cursor-pointer w-full mr-0;

        svg {
            width: 15px;
            height: 15px;
        }

        svg path {
            fill: #ffffff;
        }

        span {
            @apply hidden;
            /* &.shopify-buy__btn--price {
                @apply inline;
            } */
        }

        @screen xs {
            @apply w-auto mr-2;
        }

        @screen md {
            @apply w-full mr-0;
        }

        @screen lg {
            @apply w-auto mr-2;
        }
    }
}

.product_button--whereToBuy {
    @screen md {
        @apply button--green;
    }
}

/* Text on image styling */
.text-on-image .product_element--shopify .button {
    @apply w-auto inline-block;
}

.product_element_price-only--shopify {
    @apply text-red-500 bg-white text-xs font-extrabold uppercase items-center justify-center;
    display: inline-flex !important;
    /* height: 34px; */

    .button {
        @apply hidden;
    }

    .price {
        @apply block;
    }

    .price_old {
        font-size: 12px;
    }
}

.shopify-cart {
    border-radius: 4px;
}

.shopify-cart-edit-quantity {
    @apply flex items-center justify-center bg-green-100 shadow-md text-green-500 font-semibold text-lg rounded-md;
    width: 40px;
    height: 40px;

    @screen md {
        width: 42px;
        height: 42px;
    }
}

.shopify-cart-quantity {
    @apply bg-white border border-green-500 shadow-md text-green-500 font-bold text-lg text-center rounded-md;
    width: 46px;
    height: 42px;
}

.price-promotion {
    @apply line-through;
    text-decoration-thickness: 2px;
}

.shopify-cart-close {
    @apply absolute p-2;

    right: 0;
    top: 0;
}

.react-add-to-cart.clicked {
    @apply button--border-green bg-white text-green-500 relative;
}

.react-add-to-cart.clicked::before {
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    @apply bg-white absolute;

    z-index: 9;
}

.react-add-to-cart.clicked::after {
    content: '';
    height: 70%;
    width: 70%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23009196' viewBox='0 0 24 24'%3E%3Cdefs/%3E%3Cpath fill='none' d='M0 0h24v24H0V0z'/%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E") no-repeat center center;
    background-size: contain;
    @apply absolute;

    z-index: 10;
}

.discount-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: currentColor;
    @apply text-grey mr-2;
}

.edit-checkout-item {
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance:textfield;
    }
}

.cart-alert {
    padding: 15px 15px 160px 15px;
    @screen md {
        padding-bottom: 60px;
        padding-top: 40px;
    }
}

#shopify-overwrite .sp-t--lg {
    @apply mt-4;

    @screen md {
        @apply mt-8;
    }
}

#shopify-overwrite p {
    margin-top: 0;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.product-popup__overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 250ms;
    z-index: 50;
    padding: 20px;
    overflow-y: auto;
}
.product-popup__wrapper {
    margin: 0 auto;
    background: #fff;
    max-width: 800px;
    position: relative;
    transition: all .25s ease-in-out;
}

.popup h2 {
    margin-top: 0;
    color: #333;
    font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
}
.popup .close:hover {
    cursor: pointer;
    color: #000;
}

@media screen and (min-width: 768px) {
    .overlay {
        padding-bottom: 60px;
        padding-top: 40px;
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-spinner {
    @apply w-8 h-8 border-4 border-green-500 border-t-green-lightest rounded-full;
    animation: spinner 1.5s linear infinite;
}
