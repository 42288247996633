.communication-bar__wrapper {
    .banner-message {
        p {
            @apply mt-0;
        }
    }
    .flickity-prev-next-button:disabled {
        @apply hidden;
    }
}

.banner-message {
    p:first-child {
        @apply mt-0;
    }
}
