[data-map] {
    max-height: 75vh;
    height: 100vw;
}

@supports (-webkit-overflow-scrolling: touch) {
    [data-sku] {
        -webkit-appearance: none;
    }
}

[data-geolocate].loading img {
    display: inline-block;
}

.address a {
    display: block;
}

.address a+a {
    margin-top: .7em;
}

/* .si-content-wrapper {}
.si-close-button {} */
.si-content {
    font-family: 'Catamaran';
}


.product_nearby {

    @screen xl {
        @apply pr-10;
    }

    .js-stores a {
        @apply col w-1/3 py-4;

        @screen md{
            @apply w-1/3
        }

        img {
            @apply w-full;
            object-fit: contain;
            object-position: 0 50%;
            height: 45px;
        }

        strong {
            @apply block font-normal text-black mt-2;
        }

        small {
            @apply block leading-tight text-xs text-grey-lighter;
        }
    }

    .js-stores-brands {
        img {
            object-fit: contain;
            object-position: 0 50%;
            height: 50px;
            max-width: 100px;
        }
    }

}
