.richtext {
    @apply text-sm md:text-base;
    color: var(--intro-color);

    /* Remove margin from first child so you can handle margin from the .richtext element */
    h2:first-child,
    h3:first-child,
    h4:first-child,
    ul:first-child,
    p:first-child {
        @apply mt-0;
    }
    h2 {
        @apply t-beta mt-6;
        color: var(--heading-color, #303535);
    }
    h3 {
        @apply t-gamma mt-4;
        color: var(--heading-color, #303535);
    }
    p {
        @apply mt-4;
    }
    ul {
        @apply mb-6 mt-4;
    }
    ul > li {
        @apply relative pl-5 mt-2;
    }
    ul > li::before {
        @apply absolute bg-grey;
        content: '';
        top: 9px;
        left: 0;
        border-radius: 50%;
        width: 5px;
        height: 5px;
    }
    ul.sitemap > li {
        @apply pt-3;
        border-top:#c2dfe3 1px solid;
    }
    ul.sitemap > li::before {
        top: 22px;
    }
    ul > li + ul {
        @apply pl-6;
    }
    ul > li + ul > li + ul {
        @apply pl-12;
    }
    a {
        @apply text-green-500 font-bold;
    }
    a.button--green {
        @apply text-white;
    }
}

.richtext.richtext--flyout {
    a {
        @apply text-gray-800 underline font-normal;
    }
    a:hover {
        @apply no-underline;
    }
}

.richtext.richtext--white {
    a {
        @apply text-white;
    }
}

.richtext.richtext--green-headings {
    h2, h3 {
        @apply text-green-500;
    }
}

.richtext_links {
    a {
        @apply text-green-500 font-bold;
    }
}

.richtext.richtext--highlight {
    h2 strong {
        @apply text-4xl md:text-6xl font-bold;
    }
    h2 strong,
    h3 strong {
        @apply highlight--green;
    }
}
