a {
	text-decoration: none;
	color:inherit;
	transition:all .2s ease-out;
}

.button {
	@apply inline-flex items-center justify-center text-sm font-bold border border-green-500 text-green-500 shadow-md px-2.5 py-2 rounded-md;
	transition: background .2s ease-out, color .2s ease-out, border-color .2s ease-out;
	@screen md {
		@apply text-base;
		min-height: 43px;
	}
}
.detail-add-to-cart-button {
	@screen md {
		@apply px-7 py-2;
		@apply text-lg;
		min-height: 60px;
	}
}
.button--white {
	@apply border-white bg-white text-green-500;

	&:hover {
		@apply bg-green-75 text-green-600;
	}
}
.button--green {
	@apply border-green-500 bg-green-500 text-white;

	&:hover {
		@apply border-green-700 bg-green-700;
	}
}

.button--small {
    padding: 0.3rem 0.6rem .2rem;
    font-size: 0.8rem;
}

.button--with-icon {
	white-space: nowrap;
	svg {
		@apply inline-block mr-1 -mt-1;
	}
}

.button--img{
	padding:6px 1rem;
}

/* .button--green{
	@apply bg-green-400 border-green-400 text-white;
	&:hover {
		@apply bg-green-500 border-green-500 text-white;
	}
} */

.button--border-green{
	@apply bg-white border-green-500 text-green-500;
	&.active{
		@apply bg-green-400 border-green-400;
		box-shadow: 0 4px 0 rgba(0, 145, 150, 0.1);
	}

	&:hover {
		@apply border-green-600 bg-green-75 text-green-600;
	}
}

/* .button--white{
	@apply bg-white text-green-500 border-white;
	&:hover {
		@apply text-red-500;
	}
	&.mobile-green{
		@apply bg-green-500 border-green-500 text-white;
		&:hover {
			@apply bg-green-500 border-green-500;
		}
		@screen lg {
			@apply bg-white border-white text-green-500;
			&:hover {
				@apply text-red-500 bg-white border-white;
			}
		}
	}
} */

/* .arrow--link {
	&:hover {
		@apply text-green-500 relative pl-1;
		svg {
			@apply ml-3;
		}
	}
	svg {
		transition:all .2s ease-out;
	}
} */

.link--wrap {
	transition:all .2s ease-out;
	top:0;
	&:hover {
		/* box-shadow:0 0 0 8px rgba(0,145,150,.15); */
		@apply relative;
		top:-6px;
	}
}

/* .video-block .thumb {
	@apply border-2;
	@layer .border-green-400;
	cursor:pointer;
	transition:all .2s ease-in;
	&.active, &:hover {
		@apply border-green-500;
	}
} */
