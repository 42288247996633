html {
    font-size: 100%;
}

body {
    @apply text-black font-catamaran leading-normal;
}
body.extra-font {
    @apply font-fallbackFonts;
}

p {
    @apply mt-4;
}

strong {
    @apply font-bold;
}
